<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header text-center">
          <strong> Agregar calificacion HSE </strong
          ><i class="fa fa-heartbeat"></i>
        </div>
        <div class="card-body">
          <!-- FORMULARIO AGREGAR ITEMS PADRES  -->
          <form class="form-inline">
            <!-- nombre calificacion -->
            <div class="form-group mx-sm-3 mb-2">
              <label for="inputPassword2" class="sr-only"
                >Calificacion Padre</label
              >
              <input
                type="text"
                class="form-control"
                placeholder="Calificacion Padre"
                v-model="form_porcentaje_general.nombre"
                :class="
                  $v.form_porcentaje_general.nombre.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              />
            </div>
            <!-- peso `porcentaje -->
            <div class="form-group mx-sm-3 mb-2">
              <label for="inputPassword2" class="sr-only"
                >Peso Calificacion</label
              >
              <input
                type="number"
                class="form-control"
                placeholder="Peso calificacion"
                v-model="form_porcentaje_general.peso"
                :class="
                  $v.form_porcentaje_general.peso.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              />
            </div>
            <!-- boton de guardado -->
            <button
              type="button"
              class="btn btn-primary mb-2"
              @click="save_detalle_hse()"
              v-show="!$v.form_porcentaje_general.$invalid"
            >
              Agregar Calificacion
            </button>
          </form>
          <br />
          <!-- LO QUE VALLA A GENERAR PARA PINTAR -->
          <div id="accordion">
            <div
              class="card"
              v-for="item_padre in porcentajes_generales"
              :key="item_padre.id"
            >
              <div class="card-header" id="headingOne">
                <div class="row">
                  <div class="col-md-6">
                    <h5 class="mb-0">
                      <button
                        class="btn btn-info"
                        data-toggle="collapse"
                        v-bind:data-target="'#collapse' + item_padre.id"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        {{ item_padre.nombre }}
                        <span class="badge badge-warning"
                          >{{ item_padre.peso_porcentaje }} %
                        </span>
                      </button>
                    </h5>
                  </div>
                  <div class="col-md-6 text-right">
                    <button
                      class="btn btn-danger btn-sm"
                      @click="deleted_calificacion_general(item_padre.id)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    |
                    <button
                      class="btn btn-info btn-sm"
                      @click="show_modal_det_calificacion_hse(item_padre.id)"
                    >
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div
                v-bind:id="'collapse' + item_padre.id"
                class="collapse"
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div class="card-body">
                  <div
                    class="card bg-light"
                    v-for="item_hijo in item_padre.det_califaciones_hse"
                    :key="item_hijo.id"
                  >
                    <div class="card-header">
                      <div class="row">
                        <div class="col-md-6">
                          {{ item_hijo.nombre }}
                          <span class="badge badge-success"
                            >{{ item_hijo.peso_porcentaje }} %
                          </span>
                        </div>
                        <div class="col-md-6">
                          <div class="input-group">
                            <div
                              class="input-group-append"
                              style="margin-left: 70%"
                            >
                              <button
                                class="btn btn-outline-info dropdown-toggle"
                                type="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Acciones
                              </button>
                              <div class="dropdown-menu">
                                <a
                                  class="dropdown-item"
                                  v-if="item_hijo.aplica_calculo == 1"
                                  v-on:click="
                                    show_modal_det_calificacion_hse_especifica(
                                      item_hijo.id,
                                      'HSE'
                                    )
                                  "
                                  ><i class="fa fa-user-plus"></i> Aplica para
                                  novedades HSE
                                </a>
                                <a
                                  class="dropdown-item"
                                  v-if="item_hijo.aplica_calculo == 1"
                                  v-on:click="
                                    show_modal_det_calificacion_hse_especifica(
                                      item_hijo.id,
                                      'Mantenimiento'
                                    )
                                  "
                                >
                                  <i class="fa fa-truck"></i> Aplica para
                                  novedades mantenimiento</a
                                >
                                <a
                                  class="dropdown-item text-danger"
                                  v-on:click="
                                    delete_calificacion_detalle_hse(
                                      item_hijo.id
                                    )
                                  "
                                >
                                  <i class="fa fa-trash"></i> Eliminar Item</a
                                >
                                <a
                                  class="dropdown-item text-danger"
                                  v-if="
                                    item_hijo.aplica_calculo == 2 ||
                                    item_hijo.aplica_calculo == 3
                                  "
                                  v-on:click="
                                    save_detalle_det_calificacion_hse(
                                      2,
                                      item_hijo.id
                                    )
                                  "
                                >
                                  <i class="fa fa-minus-circle"></i> Eliminar
                                  calculo novedades</a
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button class="btn btn-primary" @click="save_calificacion_general()">
            Guardar <i class="fas fa-paper-plane"></i>
          </button>
          |
          <button class="btn btn-secondary" @click="cerrar_modal()">
            Cerrar <i class="fas fa-reply"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- 
            |--------------------------------------------------------
            |********************** SECCION MODALES *****************
            |--------------------------------------------------------
                -->
    <!-- modal detalle calificacion -->
    <div class="modal fade" id="Modal_det_calificacion_hse">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Agregar Detalle calificacion HSE
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              id="close-modal"
            >
              Cancelar
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- Cuerpo del modal -->
          <div class="modal-body">
            <div class="row">
              <!-- campo Nombre -->
              <div class="col-lg-12">
                <div class="form-group">
                  <label for="recipient-name" class="col-form-label"
                    >Nombre</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="recipient-name"
                    v-model="form_detalle_calificacion_hse.nombre"
                    :class="
                      $v.form_detalle_calificacion_hse.nombre.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
              </div>
              <!-- /. campo Nombre -->
              <div class="col-lg-12">
                <div class="form-group">
                  <label for="recipient-name" class="col-form-label"
                    >Peso Porcentaje</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="peso_porcentaje"
                    v-model="form_detalle_calificacion_hse.peso"
                    :class="
                      $v.form_detalle_calificacion_hse.peso.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
              </div>
              <!-- /. campo Nombre -->
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.form_detalle_calificacion_hse.$invalid"
              @click="save_detalle_calificacion_hse()"
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- modal novedades -->
    <div class="modal fade" id="Modal_novedades">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h6 class="modal-title text-white">
              Seleccion de items novedades {{ encabezado_modal }}
            </h6>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              id="close-modal"
            >
              Cancelar
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- Cuerpo del modal -->
          <div class="modal-body">
            <div class="form-group text-left">
              <div
                class="form-check"
                v-for="item_inspeccion in detalle_items"
                :key="item_inspeccion.id"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-bind:value="item_inspeccion.id"
                  v-bind:id="item_inspeccion.id"
                  v-model="detalle_items_seleccion"
                />
                <label class="form-check-label" for="defaultCheck1">
                  {{ item_inspeccion.nombre }}
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              @click="save_detalle_det_calificacion_hse(1, null)"
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
const $ = window.$;
export default {
  name: "CalificacionesHSE",
  props: ["bandera", "calificacion"],
  data() {
    return {
      bandera_hijo: 0,
      form_porcentaje_general: {},
      form_detalle_calificacion_hse: {},
      porcentajes_generales: {},
      encabezado_modal: "",
      detalle_items: {},
      detalle_items_seleccion: [],
      det_califaciones_hse_id: "",
    };
  },
  validations: {
    form_porcentaje_general: {
      nombre: {
        required,
      },
      peso: {
        required,
      },
    },
    form_detalle_calificacion_hse: {
      nombre: {
        required,
      },
      peso: {
        required,
      },
    },
  },
  methods: {
    /*
     |--------------------------------------------------------------------------
     | Funciones Calificacion HSE padre
     |--------------------------------------------------------------------------
     | cerrar_modal() => Cierra componente de calificaciones HSE
     | save_detalle_hse() => Guarda la calificacion HSE
     | cargar_calificaciones_hse() => carga las calificaciones HSE asociadas
     | deleted_calificacion_general() => elimina la calificacion general y sus detalles
     */
    cerrar_modal() {
      this.bandera_hijo = 1;
      this.$emit("bandera_hijo_emit", this.bandera_hijo);
    },
    save_detalle_hse() {
      if (
        this.form_porcentaje_general.nombre != undefined &&
        this.form_porcentaje_general.peso != undefined
      ) {
        var sumCalificaciongeneraleHSE = 0;

        for (let o = 0; o < this.porcentajes_generales.length; o++) {
          sumCalificaciongeneraleHSE =
            sumCalificaciongeneraleHSE +
            parseInt(this.porcentajes_generales[o].peso_porcentaje);
        }

        sumCalificaciongeneraleHSE =
          sumCalificaciongeneraleHSE +
          parseInt(this.form_porcentaje_general.peso);

        //validar que no sobrepase el total de los items el 100%
        if (sumCalificaciongeneraleHSE > 100) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "La suma de los porcentajes no puede ser mayor al 100%",
          });
        } else {
          this.form_porcentaje_general.calificacion_id = this.calificacion;

          axios({
            method: "POST",
            url:
              "/api/hidrocarburos/Porcentajescalificaciones/save_Detalle_calificacion_hse",
            data: this.form_porcentaje_general,
          })
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "la seccion se guardo exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.cargar_calificaciones_hse();
            })
            .catch((e) => {
              this.$swal({
                icon: "error",
                title:
                  "Ocurrio un error por favor comuniquese con el administrador del sistema..." +
                  e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      } else {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "debe diligenciar todos los datos",
        });
      }
    },
    cargar_calificaciones_hse() {
      axios
        .get(
          "/api/hidrocarburos/Porcentajescalificaciones/list_Detalle_calificacion_hse",
          {
            params: {
              calificacion_id: this.calificacion,
            },
          }
        )
        .then((response) => {
          this.porcentajes_generales = response.data;
        });
    },
    deleted_calificacion_general(calificacion_hse_id) {
      this.$swal({
        title: "Esta seguro de eliminar esta calificacion?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        // <--
        if (result.value) {
          axios
            .delete(
              "/api/hidrocarburos/Porcentajescalificaciones/destroy_calificacion_hse/" +
                calificacion_hse_id
            )
            .then(() => {
              this.$swal({
                icon: "success",
                title: "Se elimino la calificacion de manera exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
              this.cargar_calificaciones_hse();
            });
        }
      });
    },
    /*
     |--------------------------------------------------------------------------
     | Funciones Calificacion HSE Hijas
     |--------------------------------------------------------------------------
     | show_modal_det_calificacion_hse() => Vselect empresas
     | save_detalle_calificacion_hse() => Carga datos del select de empresa
     | assingOrRevokeEmpresa() => asigna o elimina empresa de una calificacion
     */
    show_modal_det_calificacion_hse(calificacion_hse_id) {
      $("#Modal_det_calificacion_hse").modal("show");
      this.form_detalle_calificacion_hse.calificacion_hse_id = calificacion_hse_id;
    },
    save_detalle_calificacion_hse() {
      //validacion para no sobrepasar el peso del item
      var validacion_peso = this.porcentajes_generales.filter(
        (porcentaje) =>
          porcentaje.id ==
          this.form_detalle_calificacion_hse.calificacion_hse_id
      );
      let peso_general_item = validacion_peso[0].peso_porcentaje;
      let peso_det_grabado = 0;
      let peso_a_grabar = parseInt(this.form_detalle_calificacion_hse.peso);
      let peso_total = 0;

      for (let i = 0; i < validacion_peso[0].det_califaciones_hse.length; i++) {
        peso_det_grabado =
          peso_det_grabado +
          parseInt(validacion_peso[0].det_califaciones_hse[i].peso_porcentaje);
      }

      peso_total = peso_det_grabado + peso_a_grabar;

      if (peso_total > peso_general_item) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text:
            "La suma de los porcentajes no puede ser mayor a " +
            peso_general_item +
            " % ",
        });
      } else {
        axios({
          method: "POST",
          url:
            "/api/hidrocarburos/Porcentajescalificaciones/save_Detalle_especifico_calificacion_hse",
          data: this.form_detalle_calificacion_hse,
        })
          .then((response) => {
            this.$swal({
              icon: "success",
              title: "la seccion se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargar_calificaciones_hse();
            this.form_detalle_calificacion_hse = {};
            $("#Modal_det_calificacion_hse").modal("hide");
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title:
                "Ocurrio un error por favor comuniquese con el administrador del sistema..." +
                e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
    delete_calificacion_detalle_hse(calificacion_hse_id) {
      this.$swal({
        title: "Esta seguro de eliminar esta calificacion?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        // <--
        if (result.value) {
          axios
            .delete(
              "/api/hidrocarburos/Porcentajescalificaciones/destroy_Detalle_Especifico_calificacion_hse/" +
                calificacion_hse_id
            )
            .then(() => {
              this.$swal({
                icon: "success",
                title: "Se elimino la calificacion de manera exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
              this.cargar_calificaciones_hse();
            });
        }
      });
    },
    show_modal_det_calificacion_hse_especifica(
      calificacion_hse_id,
      encabezado
    ) {
      this.det_califaciones_hse_id = calificacion_hse_id;

      this.encabezado_modal = encabezado;
      this.detalle_items = {};
      this.detalle_items_seleccion = [];

      $("#Modal_novedades").modal("show");

      axios
        .get(
          "/api/hidrocarburos/Porcentajescalificaciones/consulta_items_inspeccion_calificacion_hse",
          {
            params: {
              tipo_consulta: this.encabezado_modal,
            },
          }
        )
        .then((response) => {
          this.detalle_items = response.data;
        });
    },
    save_detalle_det_calificacion_hse(accion, det_calificacion_hse_id) {
      if (accion == 1) {
        var datos = {
          det_calificacion_id: this.det_califaciones_hse_id,
          detalle_items: this.detalle_items_seleccion,
          accion: accion,
          tipo_calificacion: this.encabezado_modal,
        };
        axios({
          method: "POST",
          url:
            "/api/hidrocarburos/Porcentajescalificaciones/assingOrRevokeItemsInspeccion",
          data: datos,
        })
          .then((response) => {
            this.$swal({
              icon: "success",
              title: "la seccion se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargar_calificaciones_hse();
            $("#Modal_novedades").modal("hide");
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title:
                "Ocurrio un error por favor comuniquese con el administrador del sistema..." +
                e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } else if (accion == 2) {
        this.$swal({
          title: "Esta seguro de eliminar el detalle",
          text: "Los cambios no se pueden revertir!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Eliminar!",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          // <--
          if (result.value) {
            var datos = {
              det_calificacion_id: det_calificacion_hse_id,
              accion: accion,
              tipo_calificacion: this.encabezado_modal,
            };
            axios({
              method: "POST",
              url:
                "/api/hidrocarburos/Porcentajescalificaciones/assingOrRevokeItemsInspeccion",
              data: datos,
            })
              .then((response) => {
                this.$swal({
                  icon: "success",
                  title: "La relacion del item fue eliminada...",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                this.cargar_calificaciones_hse();
              })
              .catch((e) => {
                this.$swal({
                  icon: "error",
                  title:
                    "Ocurrio un error por favor comuniquese con el administrador del sistema..." +
                    e,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              });
          }
        });
      }
    },

    /* 
    | GUARDADO GENERAL
    */
    save_calificacion_general() {
      var datos = {
        calificacion_id: this.calificacion,
      };
      this.$swal({
        title: "Esta seguro de guardar el detalle de la calificacion HSE",
        text: "Una vez guardado no se podran modificar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        // <--
        if (result.value) {
          axios({
            method: "POST",
            url:
              "/api/hidrocarburos/Porcentajescalificaciones/save_calificacion_general",
            data: datos,
          })
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Se guardo la calificacion HSE",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.bandera_hijo = 1;
              this.$emit("bandera_hijo_emit", this.bandera_hijo);
              this.$emit("cambio_estado_emit",2);
            })
            .catch((e) => {
              this.$swal({
                icon: "error",
                title:
                  "Ocurrio un error por favor comuniquese con el administrador del sistema..." +
                  e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
  },
  mounted() {
    this.cargar_calificaciones_hse();
  },
};
</script>